
<template>

    <NavBar />
    <HeroSection />
    <AboutSection />


    <section>

        <!-- Carousel bg -->
        <Carousel
            v-slot="{ currentSlide, onDiscoverSlide, onProjectsSlide, onMarketSlide, onTunerSlide, onInterestSlide, onImpactSlide }"
            class="xl:scale-[0.85] md:scale-[0.8] relative flex justify-center bg-plWhite w-full h-[580px]">

            <!-- Carousel clip -->
            <div class="absolute xl:scale-[1.4] lg:scale-[1.2] overflow-hidden pt-[70px] pt-[70px] -top-[0px] bg-plGray md:rounded-lg sm:rounded-lg">

                <!-- Carousel Container -->

                <div 
                    class="relative  w-180 md:h-[570px] sm:h-[670px] sm:px-10  lg:px-10 xl:px-2 sm:w-[370px] lg:w-[980px] md:w-[780px] xl:w-[1220px] bg-plBlack lg:rounded-lg md:rounded-lg sm:rounded-lg ">
                    <img class="absolute md:-top-[218px] lg:-top-[388px] sm:-top-[0px] md:-right-[230px] lg:-right-[82px] sm:-right-[70px] sm:scale-[1.2] lg:scale-50 md:scale-[0.72] xl:scale-[.398] xl:-right-[82px] xl:-top-[562px] "
                        src="./assets/pl_carousel/pl_phoneinhand.png" alt="">
<div class="z-0 md:hidden absolute  w-180 md:h-[570px] sm:h-[670px] sm:-mx-10  lg:px-10 xl:px-2 sm:w-[370px] lg:w-[980px] md:w-[780px] xl:w-[1220px] 
                                bg-gradient-to-t from-plBlack
                                lg:rounded-lg md:rounded-lg sm:rounded-lg sm:bottom-[0rem]">
                </div>


                    <div class="flex  flex-row relative justify-evenly right-4">
                        <!--

ref="swiper2" :modules="modules" :slides-per-view="1" :space-between="10" navigation
                            :autoplay="true" :pagination="{ clickable: true }" :scrollbar="{ draggable: true }"
                            @swiper="onSwiper" @slideChange="onSlideChange"
-->


                        <!-- Carousel Images wrapper -->
                        <div
                            class=" xl:absolute lg:absolute md:absolute sm:absolute absolute sm:scale-[.457] sm:-left-[44px] sm:-top-[260px]  lg:scale-50 lg:-top-[234px] md:scale-[.58] md:left-[297px] md:-top-[224px]  lg:left-[264px] overflow-hidden  rounded-[46px] bg-white w-[370px] h-[796px] xl:left-[417px] xl:-top-[241px]  ">


                            <!-- Carousel Slides -->
                            <Slide v-for="(slide, index) in carouselSlides" :key="index"
                                class=" slides  duration-700 ease-in-out">
                                <div v-show="currentSlide === index + 1" class="slide-info">
                                    <img :src="require(`./assets/pl_carousel/${slide}.png`)"
                                        class="block  absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                                        alt="...">
                                </div>
                            </Slide>

                        </div>


                        <!-- PL App Icon Dynamic controls -->
                        <div class="  sm:invisible md-invisible lg:visible xl:visible relative xl:right-[290px] lg:right-[290px] top-10 right-[100px] group">
                            <div
                                class=" absolute   h-[152px] w-[152px]  bg-plBlack90  group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  left-[74px] top-[170px] rounded-full ">
                            </div>

                            <div
                                class=" absolute   h-[140px] w-[140px]  bg-plBlack     left-[80px] top-[176px] rounded-full ">
                            </div>

                            <div v-for="(icon, index) in carouselIconSlides" :key="index" class="">
                                <transition name="slide2">
                                    <div v-show="currentSlide === index + 1" class="slide-info">

                                        <img :src="require(`./assets/pl_carousel/${icon}.svg`)" alt=""
                                            class=" relative right-20 hover:bg-opacity-90 left-[140px] top-[235px] scale-[3.4] ">
                                    </div>
                                </transition>

                            </div>
                        </div>


                        <!-- PL App Features Carousel Content -->
                        <div class="relative py-40 sm:-left-[4rem] sm:top-[16rem] md:-left-80 md:top-10 lg:left-40 lg:top-10">
                            <div v-for="(cSlideTitle, index) in cSlideTitles" :key="index" class="">
                                <transition name="slide2">
                                    <div v-show="currentSlide === index + 1" class="slide-info">
                                        <h1
                                            class="font-isidora_serif_semibold absolute  top-[120px] right-[30px] w-16  text-white text-3xl ">
                                            {{ cSlideTitle }}

                                        </h1>
                                    </div>
                                </transition>

                            </div>
                            <div v-for="(cSlideDescription, index) in cSlideDescriptions" :key="index" class="">
                                <transition name="slide2">
                                    <div v-show="currentSlide === index + 1" class="slide-info">
                                        <p
                                            class="  absolute  top-[180px] -right-[120px]  pl-[46px] w-[260px] font-extralight text-sm text-left text-white ">
                                            {{ cSlideDescription }}
                                        </p>
                                    </div>
                                </transition>

                            </div>
                            <!-- Slider indicators -->
                            <div class=" absolute flex  -right-[140px] -bottom-10  space-x-3 ">

                                <div @click="onDiscoverSlide"
                                    class="swiper relative group flex justify-center item-center ">
                                    <!-- Discover Icon -->

                                    <div id="carousel-indicator-1"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="true" aria-label="Slide 1">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_discover_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div @click="onProjectsSlide" class="relative group flex justify-center item-center ">
                                    <!-- Projects Icon -->

                                    <div id="carousel-indicator-2"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 2">

                                        <div
                                            class=" top-[.9px] bg-plBlack right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_projects_dark.svg" alt=""
                                                class="  block  p-[5.8px] hover:  bg-opacity-90 rounded-[8px]">

                                        </div>
                                    </div>

                                </div>
                                <div @click="onMarketSlide" class="relative group flex justify-center item-center ">
                                    <!-- Marketplace Icon -->

                                    <div id="carousel-indicator-3"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 3">

                                        <div
                                            class=" bg-plBlack top-[0.9px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_marketplace_dark.svg" alt=""
                                                class="  block  p-[5.8px] hover:  bg-opacity-90 rounded-[8px]">

                                        </div>
                                    </div>

                                </div>
                                <div @click="onTunerSlide" class="relative group flex justify-center item-center ">
                                    <!-- Tuner Icon -->

                                    <div id="carousel-indicator-4"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 4">

                                        <div
                                            class=" top-[0.9px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_tuner_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div @click="onInterestSlide" class="relative group flex justify-center item-center ">
                                    <!-- Interest Icon -->

                                    <div id="carousel-indicator-5"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 5">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_interest_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div @click="onImpactSlide" class="relative group flex justify-center item-center ">
                                    <!-- Impact Icon -->

                                    <div id="carousel-indicator-6"
                                        class="w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 6">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="./assets/pl_carousel/pl_slide_impact_dark.svg" alt=""
                                                class="  w-[27px] h-[27px] block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                                
            </div>

        </Carousel>
    </section>



    <!-- Placeholder Template Container  [Disposable] -->
    <div class="pt-40 flex justify-center ">

        <div class="  py-20  md:mx-16 xl:pt-60 xl:scale-[1.2] xl:my-14">
            <div class=" flex xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-row">

                <div class="  ">
                    <img src="./assets/pl_People.svg" alt="" class=" w-[450px] ">


                </div>
                <div class=" md:ml-12 sm:ml-20 relative -top-8">
                    <img src="./assets/pl_images/pl_diaspora_post.png" alt="" class=" w-[480px] pr-20 ">
                </div>

            </div>
        </div>
    </div>

    <!-- Discovery Section - We Making Discovery Smarter  -->

    <div id="discover"  class="">
        <div class="flex flex-col max-w-6xl px-5 mx-auto">
            <h1
                class="font-isidora_semibold place-self-center  max-w-fit lg:text-5xl  sm:text-[32px] text-xl  font-semibold my-12 lg:my-32 text-center ">
                We Making Discovery Smarter
            </h1>

        </div>
    </div>

    <section>

        <!-- Carousel bg -->
        <Carousel class="md:scale-[0.8] xl:scale-[0.85] relative flex justify-center  w-full h-[580px]">

            <!-- Carousel clip -->
            <div class="xl:scale-[1.4] lg:scale-[1.2] overflow-hidden absolute pt-[70px] -top-[0px] ">

                <!-- Carousel Container -->
                <div
                    class="sm:w-[370px] lg:w-[980px] md:w-[800px] xl:w-[1220px] overflow-hidden relative  w-180 h-[532px] sm:px-10 md:px-10 lg:px-10 bg-plBlackBg90 lg:rounded-lg md:rounded-lg sm:rounded-lg">
                    <img class="absolute md:scale-[1] sm:scale-[1.0] ml-8 -top-[50px] color-plWhite object-cover md:-top-[10px] lg:-top-[50px] sm:-top-[20px] xl:-top-[168px] xl:scale-[.88]"
                        src="./assets/pl_images/pl_flight.png" alt="">

                    <div
                        class=" left-[130px] absolute bg-gradient-to-l from-plBlackBg90 w-[850px] h-[881px] rounded-[40px] xl:w-[1200px] ">
                    </div>
                    <div class=" absolute bg-gradient-to-r from-plBlackBg90     w-[850px] h-[881px] rounded-[40px] ">
                    </div>


                    <!-- PL App Features Carousel Content -->
                    <div class="flex justify-left relative py-40 sm:relative lg:left-0 sm:left-160  md:relative md:left-0 ">
                        <div class="">

                            <div class="">
                                <h1 class="font-isidora_serif_semibold absolute md:top-[240px]  sm:top-[140px] md:left-10 sm:left-2 text-white text-3xl ">
                                    Actionable Posts
                                </h1>
                            </div>

                        </div>

                        <div class="">
                            <p
                                class="  absolute md:top-[310px] sm:top-[290px] md:left-10 sm:left-2  h-[46px] md:w-[24rem] sm:w-[16rem]  font-extralight text-sm text-left text-white ">
                                Discovery tags on post from People & Businesses 
                                helps you access Projects, Stores, Real Estate offers, 
                                Art Galleries, Event Tickets, Tour Bookings, & more 
                            </p>
                        </div>

                        <!-- Slider indicators -->
                        <div class=" flex  -right-[140px] -bottom-10 ">


                            <div class="md:ml-8 sm:ml-0 md:mt-60 sm:mt-[272px]">
                                <div class="  md:block">
                                    <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo('#plbetaform')">
                                    <button
                                        class=" p-[2px] m-2  overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                                        <span
                                            class="flex flex-row justify-center  px-10 py-3 transition-all ease-in duration-75 bg-plBlackBg90 dark:bg-gray-900 rounded-full group-hover:bg-opacity-90">
                                            <p class="font-sans font-semibold  antialiased text-plWhite text-sm  ">
                                                Try in App Beta</p>
                                            <img class="ml-12 w-4 color-plWhite" src="./assets/pl_arrow_up_dark.svg"
                                                alt="">
                                        </span>
                                    </button>
                                    </a>
                                </div>

                            </div>



                        </div>

                    </div>

                </div>
            </div>

        </Carousel>
    </section>

    <!-- Placeholder Template Container  [Disposable] -->
    <div class="">

        <div class=" scale-[0.8] flex justify-center  py-20  md:mx-16  ">
            <div class=" lg:pt-28 flex xl:flex-row lg:flex-row md:flex-row sm:flex-col xl:scale-[1.2] xl:my-14 ">

                <div class=" sm:relative sm:left-8 ">
                    <img src="./assets/pl_images/pl_post_tags_links.svg" alt="" class=" w-[450px] px-4 ">


                </div>
                <div class="relative  ml-12 sm:ml-0 md:top-20 sm:top-2 md:text-left sm:text-center ">
                    <div class="sm:flex sm:flex-col  sm:justify-center">
                        <h4 class="text-plBlack90 text-[32px] font-isidora_semibold mb-7">
                            Tap Post for more
                        </h4>
                        <p class="text-plBlack90 text-md ">Use Discovery tags when posting <br>
                            to link posts or narratives to your <br>
                            offerings including products, <br>
                            projects, services & more <br><br>

                            Links multiple-posts <br>
                            to your Offerings</p>
                    </div>
                    <div class="mt-8">
                        <div class="  md:block">
                             <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo('#plbetaform')">
                            <button
                                class=" p-[2px] m-2  overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                                <span
                                    class="flex flex-row justify-center  px-10 py-3 transition-all ease-in duration-75 bg-plGray20 dark:bg-gray-900 rounded-full group-hover:bg-opacity-90">
                                    <p class="font-isidora_semibold  antialiased text-plBlack90 text-sm  "> Try in App
                                        Beta</p>
                                    <img class="ml-12 w-4" src="./assets/pl_arrow_up.svg" alt="">
                                </span>
                            </button>
                            </a>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>

    <!-- Placeholder Template Container  [Disposable] -->
    <div class="flex justify-center ">

        <div class="  pb-40  md:mx-16 ">
            <div class=" flex  md:flex-row sm:flex-col  xl:scale-[1.2] xl:my-14">

                <div class=" ">
                    <img src="./assets/pl_images/pl_interest_card.svg" alt="" class=" mx-auto w-[450px] px-4 ">


                </div>
                <div class="ml-12 sm:ml-0 sm:pt-10 md:pt-0">
                    <img src="./assets/pl_images/pl_impact_card.svg" alt="" class="  w-[450px] px-4 ">
                </div>

            </div>
        </div>
    </div>

    <div id="business" class="">
        <div class="flex flex-col max-w-6xl px-5 mx-auto">
            <h1 class="font-isidora_semibold place-self-center  max-w-fit lg:text-5xl  sm:text-[32px] text-xl  my-12 text-center ">
                We Making Business Easier
            </h1>

        </div>
    </div>

    <div class="flex justify-center py-20">
        <div class="lg:scale-[0.9]">
            <img src="./assets/pl_images/pl_Market_Carousel.png" alt="">
        </div>
    </div>

    <footer class=" lg:text-left text-plWhite">

        <div class=" text-right mx-6  md:mx-36">
            <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-2  ">

            </div>
        </div>

        <div class="flex lg:flex-row md:flex-col sm:flex-col justify-center text-center  pb-28   ">
            
                <div class="   lg:max-w-[600px] lg:min-w-[400px] md:ml-[0px] sm:ml-[0px] ">

                        <div class="lg:text-left md:text-center sm:text-center  md:p-0 sm:p-8   ">
                            <h4 class="text-plBlack90 text-[32px] font-isidora_serif_semibold mb-7 ">
                                Marketplace
                            </h4>
                            <p class="text-plBlack90 text-md lg:pb-0 md:pb-10 mx-auto max-w-lg">Start Selling your Products, Properties or Services with
                                Business tools, Trust ratings & Review features </p>
                        </div>

               
                </div>

                <div class="flex sm:flex-col   justify-center sm:ml-0 md:ml-24 ">
                    <div class="  md:block">

                         <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo('#plbetaform')">
                        <button
                            class=" p-[2px] m-2  overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                            <span
                                class="flex flex-row justify-center  px-10 py-3 transition-all ease-in duration-75 bg-plWhite dark:bg-gray-900 rounded-full group-hover:bg-opacity-90">
                                <p class="font-sans font-semibold  antialiased text-plBlack90 text-sm  "> Try in App
                                    Beta</p>
                                <img class="ml-12 w-4" src="./assets/pl_arrow_up.svg" alt="">
                            </span>
                        </button>
                        </a>
                    </div>
                    <div class="  md:block">
                        <a class="" href="#marketplace" @click.prevent="scrollTo(plInnerLinks)">
                        <button
                            class="bg-gray-200 p-[2px] m-2  overflow-hidden text-sm font-medium text-gray-900 rounded-full group hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                            <span
                                class="flex flex-row justify-center  px-10 py-3 transition-all ease-in duration-75 bg-plWhite dark:bg-gray-900 rounded-full group-hover:bg-opacity-90">
                                <p  class="font-sans font-semibold  antialiased text-plBlack90 text-sm  " > Learn more
                                    &#160 &#160&#160 &#160</p>
                                <img class="rotate-90 ml-12 w-4" src="./assets/pl_arrow_up.svg" alt="">
                            </span>
                        </button>
                        </a>
                    </div>

                </div>

            </div>
        
    </footer>

    <div class="md:scale-[0.8] sm:scale-[0.8] flex justify-center ">

        <div id="marketplace" class=" md:mx-16 ">
            <div class=" flex md:flex-row sm:flex-col justify-center justify-center item-center xl:scale-[1.2] xl:py-14 ">

                <div class="  ">
                    <!-- Pride Stores  -->
                    <div
                        class="relative overflow-hidden max-w-[450px] h-[450px] mt-3 mr-3 mb-3 bg-gradient-to-b from-plRedBg to-plOrangeBg rounded-[40px] min-w-[450px]">
                        <img class=" scale-[1.4] relative top-[240px] left-14 w-[430px] h-[250px] object-cover"
                            src="./assets/pl_images/pl_Stores.png" alt="">

                        <!-- PL Cards Heading Text  -->


                    <!-- PL Cards Heading Text  -->

                    <div class=" flex flex-col justify-center ">
                        <div class="  self-center relative h-[27px] w-[27px]   -top-[190px]">
                            <img src="./assets/pl_land_link.svg" alt="" class=" relative mx-auto w-6 ">
                            <div
                                class=" relative border-4 bottom-[60px] right-[34px] border-plWhite/5 m-4 h-16 w-16 rounded-full">
                            </div>
                        </div>


                            <div class="self-center relative  -top-[170px]">
                                <p class="font-isidora_semibold text-[34px]  mb-4 text-plWhite/90">Stores</p>
                            </div>

                            <div class="relative self-center  -top-[180px]">
                                <p class="font-isidora_medium text-[16px] font-light text-plWhite/70">Buy & Sell Almost
                                    Anything of Value</p>
                            </div>

                        </div>
                    </div>

                    <!-- Pride Real Estates  -->
                    <div class=" overflow-hidden max-w-[450px] h-[450px] mt-3 mr-3 -mt-[2px] rounded-[40px] min-w-[450px]">
                        <img src="./assets/pl_images/pl_RealEstate.png" alt=""
                            class="relative scale-[2] w-[450px] h-[250px] object-cover  top-[120px]">


                    <!-- PL Cards Heading Text  -->

                    <div class=" flex flex-col justify-center ">
                        <div class="  self-center relative h-[27px] w-[27px]   -top-[190px]">
                            <img src="./assets/pl_land_link.svg" alt="" class=" relative mx-auto w-6 ">
                            <div
                                class=" relative border-4 bottom-[60px] right-[34px] border-plWhite/5 m-4 h-16 w-16 rounded-full">
                            </div>
                        </div>


                            <div class="self-center relative  -top-[170px]">
                                <p class="font-isidora_semibold text-[34px]  mb-4 text-plWhite/90">Real Estates</p>
                            </div>

                            <div class="relative self-center  -top-[180px]">
                                <p class="font-isidora_medium text-[16px] font-light text-plWhite/70">Buy Real Estate
                                    Properties & Lands</p>
                            </div>

                        </div>
                    </div>


                </div>
                <!-- Pride Galleries  -->

                <div class=" relative overflow-hidden    w-[450px] h-[910px] my-3 rounded-[40px] min-w-[450px] ">

                    <img src="./assets/pl_images/pl_Gallery.png" alt=""
                        class="  absolute w-[430px] h-[550px]  scale-[1.7] top-[168px] left-20 object-cover  ">
                    <div
                        class=" absolute bg-gradient-to-b from-plBlueBg via-plBlueBg   via-transparent  overflow-hidden    w-[450px] h-[881px] rounded-[40px] ">
                    </div>



                    <!-- PL Cards Heading Text  -->

                    <div class=" flex flex-col justify-center ">
                        <div class="  self-center relative h-[27px] w-[27px]   top-[80px]">
                            <img src="./assets/pl_land_link.svg" alt="" class=" relative mx-auto w-6 ">
                            <div
                                class=" relative border-4 bottom-[60px] right-[34px] border-plWhite/5 m-4 h-16 w-16 rounded-full">
                            </div>
                        </div>

                        <div class="self-center relative  top-[110px]">
                            <p class="font-isidora_semibold text-[34px]  mb-4 text-plWhite/90">Galleries</p>
                        </div>

                        <div class="relative self-center  top-[100px]">
                            <p class="font-isidora_medium text-[16px] font-light text-plWhite/70">Collect Original
                                Artworks & Bridge with Artists</p>
                        </div>

                    </div>

                </div>




            </div>
        </div>
    </div>

    <!-- Placeholder Template Container  [Disposable] -->
    <div class="sm:scale-[0.8] md:-mt-[11rem] sm:-mt-[20.5rem] md:scale-[0.8] flex justify-center  w-full md:h-[820px] sm:h-[1420px] ">

        <div class=" md:mx-6 ">
            <div class="flex md:flex-row sm:flex-col justify-center  xl:scale-[1.2] xl:py-[101px] ">

                <!-- Pride Events  -->
                <div class="overflow-hidden bg-plBlackBg max-w-[450px] h-[620px] mr-3 rounded-[40px]  min-w-[450px] ">
                    <img src="./assets/pl_images/pl_Events.png" alt=""
                        class="relative w-[450px] h-[450px] -bottom-[170px]   object-cover ">

                    <!-- PL Cards Heading Text  -->

                    <div class=" flex flex-col justify-center ">
                        <div class="  self-center relative h-[27px] w-[27px]   -top-[390px]">
                            <img src="./assets/pl_land_link.svg" alt="" class=" relative mx-auto w-6 ">
                            <div
                                class=" relative border-4 bottom-[60px] right-[34px] border-plWhite/5 m-4 h-16 w-16 rounded-full">
                            </div>
                        </div>


                        <div class="self-center relative  -top-[370px]">
                            <p class="font-isidora_semibold text-[34px]  mb-4 text-plWhite/90">Events</p>
                        </div>

                        <div class="relative self-center  -top-[380px]">
                            <p class="font-isidora_medium text-[16px] font-light text-plWhite/70">Search & Buy Events
                                Tickets</p>
                        </div>

                    </div>
                </div>
                <!-- Pride Services  -->

                <div class="overflow-hidden bg-plLightRoseGoldBg  max-w-[450px] h-[620px] md:my-0 sm:my-3 rounded-[40px] min-w-[450px]">

                    <img src="./assets/pl_images/pl_Services.png" alt=""
                        class=" relative scale-[1.2] top-[300px] -left-2 w-[450px] h-[320px] object-cover  ">
                    <div
                        class=" relative bg-gradient-to-b from-plDeepRoseGoldBg via-plLightRoseGoldBg via-transparent  overflow-hidden  bottom-[480px]  w-[450px] h-[81px] rounded-[40px] ">
                    </div>


                    <!-- PL Cards Heading Text  -->

                    <div class=" flex flex-col justify-center ">
                        <div class="  self-center relative h-[27px] w-[27px]   -top-[340px]">
                            <img src="./assets/pl_land_link.svg" alt="" class=" relative mx-auto w-6 ">
                            <div
                                class=" relative border-4 bottom-[60px] right-[34px] border-plWhite/5 m-4 h-16 w-16 rounded-full">
                            </div>
                        </div>


                        <div class="self-center relative  -top-[320px]">
                            <p class="font-isidora_semibold text-[34px]  mb-4 text-plWhite/90">Services</p>
                        </div>

                        <div class="relative self-center  -top-[330px]">
                            <p class="text-center font-isidora_medium text-[16px] font-light text-plWhite/70">From
                                Booking Hotels & Restaurants <br>
                                to Pros, Creators & Celebs</p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>


    <Footer />
</template>

<script>
import NavBar from './components/NavBar.vue'
import AboutSection from './components/AboutSection.vue'
import HeroSection from './components/HeroSection.vue'
import CarouselSection from './components/CarouselSection.vue'
import Carousel from './components/Carousel.vue'
import Slide from './components/Slide.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: { Carousel, Slide, NavBar, AboutSection, HeroSection, CarouselSection, Footer },

    setup() {
        const carouselSlides = ['pl_discover_light', 'pl_projects_light', 'pl_marketplace_light', 'pl_tuner_light', 'pl_interests_light', 'pl_impact_light'];
        const carouselIconSlides = ['pl_slide_discover_dark', 'pl_slide_projects_dark', 'pl_slide_marketplace_dark', 'pl_slide_tuner_dark', 'pl_slide_interest_dark', 'pl_slide_impact_dark'];
        const cSlideTitles = ['Discover', 'Projects', 'Marketplace', 'Tuner', 'Interest', 'Impact'];
        const cSlideDescriptions = [
            'Search & discover Lands of People and Professionals sharing narratives about their lifework, businessses, foundations & their impact',
            'Search or discover projects making impact by your interests, industries or reactions. Bridge with project owners to engage, partner, invest or create opportunities',
            'Buy & Sell with ease locally or cross-border while discovering Stores, Galleries, Real Estates, Events & Tickets, Service Providers & more',
            'Tune in to Live Camps for impactful Conversations, Meetings & News Live & Scheduled. Listen & Subscribe to Campcast for recorded live camps',
            'Personalize your discover feed with interests accross industries of your choosing and discover impactful narratives by interest',
            'Discover People, Businesss & Organizations making Impact in Africa & beyond. View & discover verified recognition awards of impact makers'
        ];
    let plInnerLinks= "#marketplace";

        const scrollTo = (selector) => {
    document.querySelector(selector).scrollIntoView({behavior:'smooth'});
}
    


        return {
            carouselSlides, carouselIconSlides, cSlideTitles, cSlideDescriptions,plInnerLinks,scrollTo,plInnerLinks
        }
    }
}
</script>

<style>
</style>