<template>
    <div id="#home" class="">
        <!-- Main Nav Bar -->
        <div class="lg:hidden bg-plBlack90  ">
            <nav class=" relative xl:container lg:container md:container mx-auto   bg-plBlack90 ">
                <!-- Flex Container -->

                <div
                    class="md:relative flex items-center md:flex-row  md:justify-between sm:justify-between  md:mb-0 sm:mb-40 md:h-20   sm:bg-plBlack90  md:w-full sm:w-screen p-4 ">
                    <!-- Logo -->
                    <div class="flex  pt-2 pr-5 ">
                        <img class="ml-2 mb-2 h-8 w-8" src="../assets/pl_logo_dark.svg" alt="">


                        <a
                            class="font-isidora_serif_semibold  sm:hidden sm:block md:hidden lg:block px-2 text-xl text-white  pt-[2px] antialiased ">Pridelands</a>
                    </div>

                    <!-- Button -->
                    <a
                        class="font-isidora_serif_semibold tracking-tight relative right-[14px]  sm:block lg:hidden lg:block px-2 text-2xl text-white  pt-[12px] antialiased ">Pridelands</a>
                    <!-- <a href="#" class="  p-1 px-6  text-tiny text-plGray80 bg-plBlack90 rounded-full">Join ApclickBeta</a> -->

                    <div class="">
                        <svg @click="onPLMenuClick" xmlns="http://www.w3.org/2000/svg" v-show="open === false"
                            class=" relative scale-[1.3] h-8 w-8 stroke-plWhite stroke-2 top-[7px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7" />
                        </svg>

                        <svg @click="onPLMenuClick" xmlns="http://www.w3.org/2000/svg" v-show="open === true"
                            class=" relative scale-[1.3] h-8 w-8 stroke-plWhite stroke-2 top-[7px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                            <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo('#plbetaform') && toggleBetaTextOff">
                        <button 
                            class="md:hidden sm:hidden md:block relative inline-flex p-0.5 m-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                            <span
                                class="relative font-font-isidora_medium  antialiased px-5 py-1 transition-all ease-in duration-75 text-white bg-plBlack90 dark:bg-gray-900 rounded-full text-xs group-hover:bg-opacity-90">
                                Join App Beta
                            </span>
                        </button>
                        </a>
                    </div>


                </div>
            </nav>
        </div>

        <!-- On Click Nav Menu -->
        <div class=" bg-plBlack90 " v-show="open === true">
            <nav class="relative xl:container lg:container md:container mx-auto   ">
                <!-- Flex Container -->

                <div
                    class=" md:relative  sm:absolute flex items-center md:flex-row sm:flex-col md:justify-between sm:justify-start md:mb-0 sm:mb-40 md:h-20 sm:h-[740px]  sm:bg-gradient-to-b from-plBlackBg  md:w-full sm:w-screen md:-top-0 sm:-top-40 ">
                    <!-- Logo -->
                    <div class="lg:visible md:invisible sm:invisible flex pt-2 pr-5 ">
                        <img class="ml-2 mb-2 h-8 w-8" src="../assets/pl_logo_dark.svg" alt="">
                        <a
                            class=" sm:block md:hidden lg:block px-2 text-xl text-white font-isidora_serif_semibold pt-[2px] antialiased ">Pridelands</a>
                    </div>
                    <!-- Menu Items -->

                    <div class=" h-7  text-center flex md:flex-row sm:flex-col  text-lg text-white ">
                        <ul class="md:flex md:flex-row md:justify-center sm:flex sm:flex-col sm:justify-center">

                            <li class="" v-for="(link, index) in plLinks" :key="index">
                                <!-- Menu Items with pl_Separator -->
                                <div class=" justify-center xl:hidden lg:hidden md:hidden py-4 w-screen px-28 ">
                                    <div
                                        class=" md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-transparent via-plGray90/20  md:mx-4 sm:flex sm:justify-center sm:ml- sm:bg-gradient-to-r from-plGray90/20 ">
                                    </div>
                                </div>

                                <a :href="link.link"
                                    class="text-sm mx-4  hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen"
                                    @click.prevent="scrollTo2(link.link)">
                                    {{ link.name }}</a>
                                <!-- Menu Items with pl_Separator -->

                            </li>


                        </ul>




                        <div class="xl:hidden lg:hidden  py-4 w-screen px-28 ">
                            <div
                                class="md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-transparent via-plGray90/20  md:mx-4 sm:flex sm:justify-center  sm:bg-gradient-to-r from-plGray90/20  ">
                            </div>
                        </div>
                    </div>
                    <!-- Button -->

                    <!-- <a href="#" class="  p-1 px-6  text-tiny text-plGray80 bg-plBlack90 rounded-full">Join App Beta</a> -->
                    <div class="hidden md:block">
                
                                                     <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo('#plbetaform') && toggleBetaTextOff">
                        <button 
                            class="relative inline-flex p-0.5 m-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                            <span
                                class="relative font-sans font-font-isidora_medium  antialiased px-5 py-1 transition-all ease-in duration-75 text-white bg-plBlack90 dark:bg-gray-900 rounded-full text-xs group-hover:bg-opacity-90">
                                Join App Beta
                            </span>
                        </button>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <!-- On Click Nav Menu -->
    <div class="bg-plBlack90 xl:visible lg:visible md:invisible sm:invisible   ">
        <nav class="relative xl:container lg:container md:container mx-auto   ">
            <!-- Flex Container -->

            <div
                class=" md:relative  sm:absolute flex items-center md:flex-row sm:flex-col md:justify-between sm:justify-start md:mb-0 sm:mb-40 md:h-20 sm:h-[740px]  sm: md:w-full sm:w-screen md:-top-0 sm:-top-40 ">
                <!-- Logo -->
                <div class="lg:visible md:invisible sm:invisible flex pt-2 pr-5 ">
                    <img class="ml-2 mb-2 h-8 w-8" src="../assets/pl_logo_dark.svg" alt="">
                    <a
                        class=" sm:block md:hidden lg:block px-2 text-xl text-white font-isidora_serif_semibold pt-[2px] antialiased ">Pridelands</a>
                </div>
                <!-- Menu Items -->

                <div class=" h-7  text-center flex md:flex-row sm:flex-col  text-lg text-white ">
                    <ul class="md:flex md:flex-row md:justify-center sm:flex sm:flex-col sm:justify-center">

                        <li class="" v-for="(link, index) in plLinks" :key="index">
                            <!-- Menu Items with pl_Separator -->
                            <div class=" justify-center xl:hidden lg:hidden md:hidden py-4 w-screen px-28 ">
                                <div
                                    class=" md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-transparent via-plGray90/20  md:mx-4 sm:flex sm:justify-center sm:ml- sm:bg-gradient-to-r from-plGray90/20 ">
                                </div>
                            </div>

                            <a :href="link.link"
                                class="text-sm mx-4  hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen"
                                @click.prevent="scrollTo2(link.link)">
                                {{ link.name }}</a>
                            <!-- Menu Items with pl_Separator -->

                        </li>


                    </ul>




                    <div class="xl:hidden lg:hidden  py-4 w-screen px-28 ">
                        <div
                            class="md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-transparent via-plGray90/20  md:mx-4 sm:flex sm:justify-center  sm:bg-gradient-to-r from-plGray90/20  ">
                        </div>
                    </div>
                </div>
                <!-- Button -->

                <!-- <a href="#" class="  p-1 px-6  text-tiny text-plGray80 bg-plBlack90 rounded-full">Join App Beta</a> -->
                <div class=" md:block">

                                                                       <a @click="toggleBetaTextOff" href="#plbetaform" @click.prevent="scrollTo2('#plbetaform')">
                    <button  
                        class="relative inline-flex p-0.5 m-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
                        <span
                            class="xl:lg-visible md:sm-invisible relative font-font-isidora_medium  antialiased px-5 py-1 transition-all ease-in duration-75 text-white bg-plBlack90 dark:bg-gray-900 rounded-full text-xs group-hover:bg-opacity-90">
                            Join App Beta
                        </span>
                    </button>
                    </a>
                </div>
            </div>
        </nav>
    </div>

</template>

<script>
import { ref } from 'vue';

export default {

    setup() {

        let open = ref(false);

        const plLinks = [
            { name: 'Home', link: "#home" },
            { name: "Discover", link: "#discover" },
            { name: "Business", link: "#business" },
            { name: "Lands", link: "#lands" },
            { name: "Newsroom", link: "#newsroom" },
            { name: "Support", link: "#support" },
        ]


        const onPLMenuClick = () => {

            open.value = !open.value;
        }

        const scrollTo2 = (selector) => {
            document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
        }
        return { plLinks, open, onPLMenuClick, scrollTo2 }

    }
}

</script>

<style>
</style>