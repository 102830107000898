
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, doc, addDoc } from 'firebase/firestore/lite';
import { getDatabase, ref, set, push } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8iB7NW8xHjF3yWb79vOOyQtUex1pR5i4",
  authDomain: "pridelands-io.firebaseapp.com",
  databaseURL: "https://pridelands-io-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pridelands-io",
  storageBucket: "pridelands-io.appspot.com",
  messagingSenderId: "184452751647",
  appId: "1:184452751647:web:381a69b5298449b062902a",
  measurementId: "G-3ZCFTDMT98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const db = getFirestore(app);
const db = getDatabase();

const database = getDatabase();
const analytics = getAnalytics(app);

// const db = firebase.addDoc
// const db = firebaseApp.firestore()


// Add a new document in collection "cities"
export async function submitWaitlist (user)  {
  console.log('Before Actual Sent');

  //return addDoc(collection(db , 'waitlist'), user)
  return push(ref(db, 'waitlist/'), user);
}