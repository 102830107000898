/*import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
*/
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './index.css'
import 'flowbite';

const app = createApp(App)

app.use(ElementPlus)
app.mount('#app')
/*
ls -alt
git init
git remote add origin https://enokia_dev@bitbucket.org/pride_labs_inc/pridelands_website.git
git push -f origin --all
*/

/*
git add .
git status
git commit -m"<add message>"
git push -u origin --all

git add .
git status
git commit -m"<Favicon. Title,Desc, Value-Statement update, hyperlinks and more.>"
git push -u origin --all
*/