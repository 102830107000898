<template>
  <section>
    <!-- Full-Width Container -->
    <div

    
      class="
      
        xl:scale-[1.4] xl:py-40
        flex flex-col
        mt-48
        
        max-w-4xl
        px-5
        mx-auto
        text-center
      "
    >
      <img class="ml-2 mb-2 h-36" src="../assets/xl_btg_icon.svg" alt="" />
      <!-- Container for Bridge the Gab -->
      <div id="plbetaform" class="p-2"></div>
      <h1
        class="
          font-isidora_serif_bold
          place-self-center
          max-w-fit
          lg:text-7xl
          text-7xl
          font-bold
          text-transparent
          bg-clip-text bg-gradient-to-r
          from-plRed
          via-plDeepYellow via-plLightYellow
          to-plGreen
          mt-4
          text-center
        "
      >
        Bridge the Gab
      </h1>
      <label
        v-if="plBetaModalActive && !plformSent"
        class="
          pt-4
          font-isidora_light
          text-transparent
          bg-clip-text bg-gradient-to-r
          from-plRed
          via-plDeepYellow via-plLightYellow
          to-plGreen
          font-4
        "
        for=""
        >Choose Mobile Device Type</label
      >

      <div
        v-if="plBetaModalActive && !plformSent"
        class="self-center my-4 h-10 w-[270px] rounded-full"
      >
        <div class="relative flex flex-row justify-center -mx-2 bottom-[1px]">
          <img
            class="
              hover:border-plDeepYellow
              relative
              bottom-2
              w-[98px]
              h-[98px]
              scale-[.5]
              p-[20px]
              border-[7px] border-f0f0f0
              rounded-full
            "
            v-if="!plAndroidUser"
            src="../assets/playStore.svg"
            alt=""
            @click="toggleAndroidUser"
          />

          <img
            class="
              border-plDeepYellow
              hover:border-plLightYellow
              relative
              bottom-2
              w-[98px]
              h-[98px]
              scale-[.5]
              p-[20px]
              border-[7px] border-f0f0f0
              rounded-full
            "
            v-if="plAndroidUser"
            src="../assets/playStore.svg"
            alt=""
            @click="toggleAndroidUser"
          />

          <img
            class="
              hover:border-plDeepYellow
              relative
              bottom-2
              w-[98px]
              h-[98px]
              scale-[.5]
              p-[20px]
              border-[7px] border-f0f0f0
              rounded-full
            "
            v-if="!plAppleUser"
            src="../assets/appleStore.svg"
            alt=""
            @click="toggleAppleUser"
          />

          <img
            class="
              border-plDeepYellow
              hover:border-plLightYellow
              relative
              bottom-2
              w-[98px]
              h-[98px]
              scale-[.5]
              p-[20px]
              border-[7px] border-f0f0f0
              rounded-full
            "
            v-if="plAppleUser"
            src="../assets/appleStore.svg"
            alt=""
            @click="toggleAppleUser"
          />
        </div>
      </div>

      <form
        @submit.prevent="handleSend"
        v-if="plBetaModalActive && !plformSent"
        class="mt-4 self-center flex flex-col w-[270px]"
        action=""
      >
        <label
          class="
            pt-8
            pb-2
            font-isidora_light
            text-transparent
            bg-clip-text bg-gradient-to-r
            from-plRed
            via-plDeepYellow via-plLightYellow
            to-plGreen
          "
          for=""
          >What's your Name ?</label
        >
        <input
          class="
            focus:outline-none focus:ring-2 focus:ring-plBlack
            font-isidora_regular
            text-center
            rounded-full
            bg-gradient-to-t
            from-plLightBlackBg90
            to-plLightBlackBg90
            border-none
            text-sm
          "
          v-model="plUserName"
          type="text"
          required
        />
        <label
          class="
            pb-2
            pt-4
            font-isidora_light
            text-transparent
            bg-clip-text bg-gradient-to-r
            from-plRed
            via-plDeepYellow via-plLightYellow
            to-plGreen
          "
          for=""
          >Enter your Email</label
        >
        <input
          required
          v-model="plUserEmail"
          type="email"
          class="
            text-sm text-center
            font-isidora_regular
            mt-1
            block
            w-full
            px-3
            py-2
            bg-gradient-to-t
            from-plLightBlackBg90
            to-plLightBlackBg90
            border border-slate-300
            rounded-full
            text-sm
            shadow-sm
            placeholder-slate-400
            focus:outline-none
            focus:border-sky-500
            focus:ring-1
            focus:ring-sky-500
            disabled:bg-slate-50
            disabled:text-slate-500
            disabled:border-slate-200
            disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600
            focus:invalid:border-pink-500 focus:invalid:ring-pink-500
          "
        />

        <button
          type="submit"
          v-show="plBetaModalActive && !plformSent"
          class="my-8 h-10 self-center w-[270px] relative"
        >
          <div
            class="
              flex flex-row
              justify-center
              bottom-[1px]
              rounded-full
              bg-gradient-to-t
              from-plLightBlackBg90
              to-plLightBlackBg90
            "
          >
            <h2
              class="
                w-[270px]
                py-[8px]
                font-isidora_semibold
                text-transparent
                bg-clip-text bg-gradient-to-r
                from-plRed
                via-plDeepYellow via-plLightYellow
                to-plGreen
              "
            >
              Send
            </h2>
          </div>
        </button>
      </form>
      <div
        v-if="!plBetaModalActive"
        class="
          self-center
          my-8
          h-10
          w-[270px]
          rounded-full
          bg-gradient-to-t
          from-plLightBlackBg90
          to-plLightBlackBg90
        "
      >
        <div class="relative flex flex-row justify-center -mx-2 bottom-[1px]">
          <img
            class="
              relative
              right-[36px]
              hover:border-plDeepYellow
              relative
              bottom-2
              w-[58px]
              h-[58px]
              scale-[.5]
              p-[10px]
              border-[4px] border-f0f0f0
              rounded-full
            "
            v-if="!plAndroidUser"
            src="../assets/playStore.svg"
            alt=""
            @click="toggleBetaModalAndroid"
          />

          <h2 
            v-if="!plformSent"
            class="
              py-[8px]
              font-isidora_semibold
              text-transparent
              bg-clip-text bg-gradient-to-r
              from-plRed
              via-plDeepYellow via-plLightYellow
              to-plGreen
            "
            @click="toggleBetaTextOff"
          >
            Join the Beta
          </h2>

          <h2
            v-if="plformSent"
            class="
              py-[8px]
              font-isidora_semibold
              text-transparent
              bg-clip-text bg-gradient-to-r
              from-plRed
              via-plDeepYellow via-plLightYellow
              to-plGreen
            "
          >
            Request Sent
          </h2>

          <img
            class="
              relative
              left-[36px]
              hover:border-plDeepYellow
              relative
              bottom-2
              w-[58px]
              h-[58px]
              scale-[.5]
              p-[10px]
              border-[4px] border-f0f0f0
              rounded-full
            "
            v-if="!plAppleUser"
            src="../assets/appleStore.svg"
            alt=""
            @click="toggleBetaModalApple"
          />
        </div>
      </div>

      <h2
        v-if="plformSent"
        class="
          self-center
          w-[270px]
          py-[8px]
          font-isidora_semibold
          text-transparent
          bg-clip-text bg-gradient-to-r
          from-plRed
          via-plDeepYellow via-plLightYellow
          to-plGreen
        "
      >
        We're proud to have you on our Waitlist! Get Ready for your Pridelands
        Beta Link soon
      </h2>
    </div>
  </section>
</template>

<script>
import { submitWaitlist } from "@/firebase";
//import {submitWaitlist} from '.../firebase'
import { ref } from "vue";

export default {
  setup() {
    let plUserName = ref("");
    let plUserEmail = ref("");
    let plAndroidUser = ref(false);
    let plAppleUser = ref(false);
    //let notplAndroidUser = ref(true);
    //let notplAppleUser = ref(true);
    let plBetaModalActive = ref(false);
    let plformSent = ref(false);
    let betaTextOn = ref(true);

    const handleSend = async () => {
      await submitWaitlist({
        name: plUserName.value,
        email: plUserEmail.value,
        android: plAndroidUser.value,
        apple: plAppleUser.value,
        date: Date.now(),
      });
      plUserName.value = "";
      plUserEmail.value = "";
      plformSent.value = true;
      plAndroidUser.value = false;
      plAppleUser.value = false;
      plBetaModalActive.value = false;
    };

    const toggleBetaModalAndroid = () => {
      if (plformSent.value == true) return;
      plBetaModalActive.value = !plBetaModalActive.value;
      toggleAndroidUser();
    };

    const toggleBetaModalApple = () => {
      if (plformSent.value == true) return;
      plBetaModalActive.value = !plBetaModalActive.value;
      toggleAppleUser();
    };

    const toggleBetaModalButton = () => {
      if (plformSent.value == true) return;
      plBetaModalActive.value = !plBetaModalActive.value;
    };

    const toggleAndroidUser = () => {
      if (plformSent.value == true) return;
      plAndroidUser.value = !plAndroidUser.value;
    };
    const toggleAppleUser = () => {
      if (plformSent.value == true) return;
      plAppleUser.value = !plAppleUser.value;
    };

    const toggleBetaTextOff = () => {
      if (plformSent.value == true) return;
      betaTextOn.value = false;
      plBetaModalActive.value = !plBetaModalActive.value;
    };

    const toggleSuccessInfo = () => {
      plAppleUser.value = !plAppleUser.value;
      plAndroidUser.value = !plAndroidUser.value;
      plformSent.value = !plformSent.value;
    };

    return {
      plUserName,
      plUserEmail,
      plAndroidUser,
      plAppleUser,
      toggleAndroidUser,
      toggleAppleUser,
      plformSent,
      plBetaModalActive,
      toggleSuccessInfo,
      toggleBetaModalButton,
      toggleBetaModalAndroid,
      toggleBetaModalApple,
      betaTextOn,
      toggleBetaTextOff,
      handleSend,
    };
  },
};
</script>

<style>
</style>