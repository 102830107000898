




<template>
<div class="bg-plBlack70 ">
  <footer id="lands" class=" lg:text-left bg-plBlack70 text-plWhite mx-auto xl:max-w-[1380px] ">

    <div class=" text-right mx-6  py-20  sm:mx-10">
      <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-2  md:mx-auto xl:mx-auto lg:mx-auto md:max-w-[650px] lg:max-w-[1230px] xl:max-w-[1180px] lg:mx-16">
        <div class="flex flex-col justify-center">
          <h6 class="
            
            
            flex flex-col
            items-start
            justify-center
            md:justify-start
            md:text-left
            text-tiny
          ">

            <img class="   h-28 w-28" src="../assets/pl_Logo_Outline_dark.svg" alt="">
          </h6>
        </div>

        <div class=" ">
          <h6 class="leading-tight text-sm font-bold  text-[44px]">
            Own Lands.<br> Make Impact .
          </h6>
        </div>

      </div>
    </div>
    <div
      class="flex justify-center items-center lg:justify-between p-[0.8px] bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen md:mx-24 sm:mx-10">
      <div class="mr-12 hidden lg:block">
      </div>
    </div>

    <div class=" text-center mx-6 py-28  md:mx-24">
      <div class="grid grid-1 md:grid-cols-1 lg:grid-cols-3  ">
        <div class="flex flex-col justify-center">
          <h6 class="
            
            
            flex flex-col
            items-start
            justify-center
            md:justify-start
            md:text-left
            text-tiny
          ">

            <p class="text-gray-300 text-sm ml-12 sm:mx-12 md:text-xl sm:relative sm:bottom-8">Pridelands is shaping how Africans, the Diaspora & People around the world bridge the <strong class="font-bold">D</strong>evelopment, <strong class="font-bold">E</strong>conomic, <strong class="font-bold">C</strong>ommunication & <strong class="font-bold">K</strong>nowledge Gab  </p>
          </h6>
        </div>

<div
      class="xl:hidden lg:hidden  py-10 ">
      <div class="md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-plGray90/20  sm:flex sm:justify-center sm:mx-4 sm:bg-gradient-to-r from-plGray90/20  sm:mx-4">
      </div>
    </div>


        <div class=" xl:flex xl:flex-col xl:justify-center lg:justify-center lg:flex lg:flex-col md:flex md:justify-between xl:pt-0 lg:pt-0 md:pt-0 ml-14 sm:flex sm:flex-row sm:justify-between sm:mx-12">
          <h6 class=" text-[24px] font-light md:my-4 sm:my-4">
            Partner
          </h6>
          <p class="mb-4 md:my-4 sm:my-4">
            <a href="#!"
              class=" relative inline-flex p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-full bg-gray-800 group hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
              <span
                class=" flex relative px-6 py-2  transition-all ease-in duration-75 text-gray-300 bg-plBlack70 dark:bg-gray-00 rounded-full text-xs group-hover:bg-opacity-90 ">
                Collaborate with Us</span>
            </a>
          </p>

        </div>
<div
      class="xl:hidden lg:hidden  py-10 ">
      <div class="md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-plGray90/20  md:mx-4 sm:flex sm:justify-center sm:mx-4 sm:bg-gradient-to-r from-plGray90/20  sm:mx-4">
      </div>
    </div>


        <div class="xl:flex xl:flex-col xl:justify-center lg:justify-center lg:flex lg:flex-col md:flex md:justify-between ml-12 sm:flex sm:flex-row sm:justify-between sm:mx-12">
          <h6 class="text-[24px] mb-7 font-light md:my-4 sm:my-4">
            Investors
          </h6>
          <p class="mb-4 md:my-4 sm:my-4">
            <a href="#!"
              class=" relative inline-flex p-0.5 overflow-hidden text-sm font-medium text-gray-400 rounded-full bg-gray-800 group hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
              <span
                class=" flex relative px-6 py-2  transition-all ease-in duration-75 text-gray-300 bg-plBlack70 dark:bg-gray-900 rounded-full text-xs group-hover:bg-opacity-90">
                Become an Investor</span>
            </a>
          </p>

        </div>

<div
      class="xl:hidden lg:hidden  py-10 ">
      <div class="md:flex md:justify-center  p-[0.8px] md:bg-gradient-to-r from-plGray90/20  md:mx-4 sm:flex sm:justify-center sm:mx-4 sm:bg-gradient-to-r from-plGray90/20  sm:mx-4">
      </div>
    </div>

      </div>
    </div>
  </footer>
  </div>

<div class="bg-plBlack80">
  <footer class=" lg:text-left bg-plBlack80 text-plWhite mx-auto xl:max-w-[1254px] lg:max-w-[1314px]">

    <div class=" text-right mx-6 xl:py-20 lg:py-20 md:py-20 md:mx-14 sm:py-10">
      <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4  sm:grid-cols-2">
        <div class=" flex flex-col  lg:justify-top xl:justify-top  sm:justify-top -mr-48 sm:-mr-8 sm:relative  sm:top-10 sm:right-2">

          <p class="font-isidora_serif_semibold text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen md:text-[48px] sm:text-[32px]  ml-4 md:ml-16 md:text-left sm:pl-4 sm:text-left md:text-center sm:text-left">
            Pridelands
          </p>
        </div>

        <div class="ml-20 md:pb-12  md:py-0 sm:py-12 md:mr-20 sm:mr-6">
          <h6 class="text-sm font-semibold mb-7 ">
            Products
          </h6>
          <p class="mb-1  ">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny ">Land</a>
          </p>
          <p class="mb-1">
            <a href="#marketplace" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny" @click.prevent="scrollTo3(plMarketFooterLink)">Marketplace</a>
          </p>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Projects</a>
          </p>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Chat</a>
          </p>
                    <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Pay</a>
          </p>
        </div>
        <div class="ml-16 md:mr-28 sm:relative sm:-left-8 md:text-right sm:text-left ">
          <h6 class="text-sm font-semibold sm:mb-7 ">
            Pride Labs, Inc
          </h6>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">About Us</a>
          </p>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Brand Toolkit</a>
          </p>

                    <p>
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Newsroom</a>
          </p>
          
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Investors</a>
          </p>
                    <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Partners</a>
          </p>

        </div>

        <div class="mr-6 xl:mr-6 lg:mr-6 md:mr-20">
          <h6 class="text-sm font-semibold mb-7   ">
            Support
          </h6>

          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Help Center</a>
          </p>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">QuickStart Guide</a>
          </p>
          <p class="mb-1">
            <a href="#!" class="hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Rules & Guidelines</a>
          </p>
          <p>
            <a href="#!" class="mb-2 hover:text-transparent bg-clip-text bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen text-gray-400 text-tiny">Contact Us</a>
          </p>

        </div>
      </div>
    </div>

  </footer>
  </div>

  <!-- Footer Bar -->
  <div class=" flex items-center justify-between bg-plBlack70 h-24">
    <nav class="relative container  mx-auto  p-2 bg-plBlack70 xl:mx-auto  xl:max-w-[1140px] lg:mx-auto lg:max-w-[1180px]">
      <!-- Flex Container -->
      <div class="flex items-center justify-between  ">
        <!-- Logo -->
        <div class="flex  pr-5 ">
          <img class="mx-2  h-5 w-5" src="../assets/pl_love_emoji.svg" alt="">
          <a class=" sm:block md:hidden lg:block px-2 text-tiny text-gray-300">Made with Love by African Founders
            &nbsp&nbsp|&nbsp&nbsp Pride Labs, Inc - USA</a>
        </div>
        <!-- Menu Items -->
        <div class="hidden md:flex space-x-7 text-tiny text-plGray80">
          <a href="#" class="hover:text-plDeepYellow">Terms</a>
          <a href="#" class="hover:text-plDeepYellow">Privacy</a>
          <a href="#" class="hover:text-plDeepYellow">English</a>

        </div>
        <!-- Button -->

        <!-- <a href="#" class="  p-1 px-6  text-tiny text-plGray80 bg-plBlack90 rounded-full">Join App Beta</a> -->
        <div class="hidden md:block">
          <button
            class=" relative inline-flex p-0.5 m- mr-2 overflow-hidden text-sm font-medium  rounded-full bg-gray-800 group hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  hover:text-white dark:text-white">
            <span
              class=" flex relative px-5  transition-all ease-in duration-75  bg-plBlack70 dark:bg-gray-900 rounded-full text-xs group-hover:bg-opacity-90">
              <img  class="ml-2 m-2 h-5 w-5" src="../assets/pl_land_link.svg" alt="">
              <img onclick=" window.open('https://www.instagram.com/pridelands_app/','_blank')" class="ml-2 m-2 h-5 w-5" src="../assets/pl_instagram_link.svg" alt="">
              <img onclick=" window.open('https://mobile.twitter.com/pridelands_app','_blank')" class="ml-2 m-2 h-5 w-5" src="../assets/pl_twitter_link.svg" alt="">
              <img onclick=" window.open('https://facebook.com/PrideLabs.inc/','_blank')" class="ml-2 m-2 h-5 w-5" src="../assets/pl_facebook_link.svg" alt="">
              <img onclick=" window.open('http://google.com','_blank')" class="ml-2 m-2 h-5 w-5" src="../assets/pl_youtube_link.svg" alt="">
            </span>
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {

  setup() {
    let plMarketFooterLink = "#marketplace";
    const scrollTo3 = (selector) => {
    document.querySelector(selector).scrollIntoView({behavior:'smooth'});
}
        return{scrollTo3, plMarketFooterLink }

}
  }


</script>

<style>
</style>