<template>
  <div class="carousel">

    <slot :currentSlide="currentSlide" :onDiscoverSlide="onDiscoverSlide" :onProjectsSlide="onProjectsSlide"
      :onMarketSlide="onMarketSlide" :onTunerSlide="onTunerSlide" :onInterestSlide="onInterestSlide"
      :onImpactSlide="onImpactSlide" />

  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {

  setup() {
    const currentSlide = ref(1);
    const getSlideCount = ref(null);
    const autoplayEnabled = ref(true);
    const timeoutDuration = ref(9000);

    //autoplay
    const autoPlay = () => {
      setInterval(() => {
       if (autoplayEnabled){
        nextSlide()
       }
        
      }, timeoutDuration.value)
    }


    onMounted(() => {
      getSlideCount.value = document.querySelectorAll(".slides").length;
      console.log(getSlideCount.value);

    });

    // Carousel Slide Icons Switches
    //onDiscoverSlide
    const onDiscoverSlide = () => {

      currentSlide.value = 1;
    }
    //onProjectsSlide
    const onProjectsSlide = () => {
      currentSlide.value = 2;
      
    }
    //onMarketSlide
    const onMarketSlide = () => {

      currentSlide.value = 3;
    }
    //onTunerSlide
    const onTunerSlide = () => {

      currentSlide.value = 4;
    }
    //onInterestSlide
    const onInterestSlide = () => {

      currentSlide.value = 5;
    }
    //onImpactSlide
    const onImpactSlide = () => {

      currentSlide.value = 6;
    }

    //nextSlide
    const nextSlide = () => {
      if (currentSlide.value === getSlideCount.value) {
        currentSlide.value = 1;
        return;
      }
      currentSlide.value += 1;
    }



if (autoplayEnabled.value) {
  autoPlay();
}

 
    return {
      currentSlide, getSlideCount, onDiscoverSlide, onProjectsSlide, onMarketSlide,
      onTunerSlide, onInterestSlide, onImpactSlide
    }
  }
} 
</script>

<style>
.slide2-enter-active.slide2-leave-active {
  transition:   0.7s ease-in-out;
}

.slide2-enter-from,
.slide2-leave-to {
 
  opacity: 0;
}
.slide2-enter-to,
.slide2-leave-from {

  opacity: 1;
}
</style>