<template>
    <section>
        <!-- Carousel bg -->
        <div class=" relative flex justify-center bg-plGray20 w-full h-[580px]">

            <!-- Carousel clip -->
            <div class=" overflow-hidden absolute pt-[70px] -top-[0px] bg-plGray">

                <!-- Carousel Container -->
                <div class=" relative  w-180 h-140 sm:px-10 md:px-10 lg:px-10 bg-plBlack rounded-lg ">
                    <img class="absolute -top-[388px] -right-[82px] scale-50"
                        src="../assets/pl_carousel/pl_phoneinhand.png" alt="">


                    <div class="flex flex-row relative justify-evenly right-4">

                        <!-- Carousel Images wrapper -->
                        <swiper ref="swiper2" :modules="modules" :slides-per-view="1" :space-between="10" navigation :autoplay="true"
                            :pagination="{ clickable: true }" :scrollbar="{ draggable: true }" @swiper="onSwiper"
                            @slideChange="onSlideChange" 
                            class="  -top-[242px] scale-[0.495] overflow-hidden absolute rounded-[50.9px] bg-white w-[370px] h-[796px] rounded-[40px]">

                            <!-- Carousel Slides -->
                            <swiper-slide v-for="(slide, index) in carouselSlides" :key="index"
                                class=" slide  duration-700 ease-in-out">
                                <img :src="require(`../assets/pl_carousel/${slide}.png`)"
                                    class="block absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                                    alt="...">
                            </swiper-slide>

                        </swiper>

                        <!-- PL App Icon Dynamic controls -->
                        <div class="group">
                            <div
                                class=" absolute   h-[152px] w-[152px]  bg-plBlack90  group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen  left-[74px] top-[170px] rounded-full ">
                            </div>

                            <div
                                class=" absolute   h-[140px] w-[140px]  bg-plBlack     left-[80px] top-[176px] rounded-full ">
                            </div>

                            <img src="../assets/pl_carousel/pl_slide_discover_dark.svg" alt=""
                                class=" absolute right-20 hover:bg-opacity-90 left-[140px] top-[235px] scale-[3.4] ">

                        </div>


                        <!-- PL App Features Carousel Content -->
                        <div class=" relative py-40 left-40 ">
                            <h1 class=" absolute  top-[170px] right-[30px] w-16  text-white text-2xl ">Discover
                            </h1>
                            <p
                                class="  absolute  top-[230px] -right-[120px]  pl-[46px] w-[260px] font-extralight text-xs text-left text-white ">
                                Search & Discover Land’s of People & Professionals sharing posts about their life,
                                Businesses & Foundations
                            </p>

                            <!-- Slider indicators -->
                            <div class=" absolute flex  -right-[140px] -bottom-5  space-x-3 ">

                                <div @click="swipeTo" class="swiper relative group flex justify-center item-center ">
                                    <!-- Discover Icon -->

                                    <div id="carousel-indicator-1"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="true" aria-label="Slide 1">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_discover_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div class="relative group flex justify-center item-center ">
                                    <!-- Projects Icon -->

                                    <div id="carousel-indicator-2"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 2">

                                        <div
                                            class=" top-[0.9px] bg-plBlack right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_projects_dark.svg" alt=""
                                                class="  block  p-[5.8px] hover:  bg-opacity-90 rounded-[8px]">

                                        </div>
                                    </div>

                                </div>
                                <div class="relative group flex justify-center item-center ">
                                    <!-- Marketplace Icon -->

                                    <div id="carousel-indicator-3"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 3">

                                        <div
                                            class=" bg-plBlack top-[0.9px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_marketplace_dark.svg" alt=""
                                                class="  block  p-[5.8px] hover:  bg-opacity-90 rounded-[8px]">

                                        </div>
                                    </div>

                                </div>
                                <div class="relative group flex justify-center item-center ">
                                    <!-- Tuner Icon -->

                                    <div id="carousel-indicator-4"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 4">

                                        <div
                                            class=" top-[0.9px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_tuner_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div class="relative group flex justify-center item-center ">
                                    <!-- Interest Icon -->

                                    <div id="carousel-indicator-5"
                                        class="   w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 5">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_interest_dark.svg" alt=""
                                                class="  block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>
                                <div class="relative group flex justify-center item-center ">
                                    <!-- Impact Icon -->

                                    <div id="carousel-indicator-6"
                                        class="w-[29px] h-[29px]  bg-plBlack90 group-hover:bg-gradient-to-r from-plRed via-plDeepYellow via-plLightYellow to-plGreen rounded-full "
                                        aria-current="false" aria-label="Slide 6">

                                        <div
                                            class=" top-[0.8px] right-[0.8px] absolute w-[27px] h-[27px]   rounded-full ">

                                            <img src="../assets/pl_carousel/pl_slide_impact_dark.svg" alt=""
                                                class="  w-[27px] h-[27px] block bg-plBlack p-[5px] hover:  bg-opacity-90 rounded-full">

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>
    </section>

</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { onMounted, ref } from 'vue';


export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        const carouselSlides = ['pl_discover_light', 'pl_projects_light', 'pl_marketplace_light', 'pl_tuner_light', 'pl_interests_light', 'pl_impact_light'];
        
        //const currentSlide = ref;
        const getSlideCount = ref(null);
        const swiper2 = ref(null);

        const swipeTo = (swiper) => {
            swiper2.value = swiper.slideNext();
            console.log('This is a swipe to test');
        };

        onMounted(() => {
            getSlideCount.value = document.querySelectorAll(".slide").length;
            //console.log(getSlideCount.value);

        });
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };



        return {
            swiper2, swipeTo, carouselSlides, onSwiper, onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
        };

    }
}


</script>

<style>
</style>