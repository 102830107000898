<template>

       <section>


        <!-- Container for AboutSection -->
        <div class="bg-gradient-to-b via-plWhite from-plLightBlackBg90  rounded-tl-[100px] rounded-tr-[100px]" >
            <div class="flex flex-col max-w-6xl px-5 mx-auto mt-32 xl:py-20 " >
                <img class=" ml-2 mb-2 h-20 mt-40 xl:scale-[1.4] xl:my-14 xl:mt-36" src="../assets/pl_logo_light.svg" alt="">
                <h1 class="font-isidora_serif_semibold place-self-center  max-w-fit lg:text-5xl  sm:text-4xl text-3xl  font-semibold mt-2 text-center xl:scale-[1.4]">
                    Pridelands
                </h1>
                <p class="sm:px-4 font-isidora_medium text-gray-500 md:text-2xl sm:text-lg mb-[70px] lg:mb-[120px] place-self-center max-w-fit  mt-4 text-center xl:mb-36 sm:w-auto">
                Share Narratives about your Business or Idea,
                    Discover Funding Opportunities, Buy & Sell and
                    Grow your Business in a new Digital Place! 
                    

                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>